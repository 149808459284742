import React from 'react'
import "./About.css"

const About = () => {
    return (
        <div className='container-about'>
            <div className='about'>
                <div className='Our-Vision'>
                    <h5>Our Vision</h5>
                    <p>
                        To be the preferred technology and e-commerce partner for businesses and consumers across Africa.
                    </p>
                </div>

                <div className='Our-Mission'>
                    <h5>Our Mission</h5>
                    <p>
                        To provide innovative and reliable solutions that enhance the digital experience and drive economic growth in the region.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About