import React, { useEffect } from "react";
import "./FormContact.css";
import { FiGlobe, FiMail, FiPhone, FiSend } from "react-icons/fi";
import { BsGeoAlt } from "react-icons/bs";

const FormContact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="main-content-form-contact">
      <div className="main-head">
        <div className="head-contact">
          <h3> Contact Us</h3>
          <p>Get in touch with our team. We're here to help.</p>
        </div>
      </div>
      <div className="form-contact">
        <div className="contact-main">
          <div className="card contact-information">
            <h3>Contact Information</h3>

            <div className="body-contact-information">
              <div className="content">
                <span>
                  <FiPhone />
                </span>

                <div>
                  <span className="title">Phone</span>
                  <span className="value">+243 892844000</span>
                </div>
              </div>

              <div className="content">
                <span>
                  <FiMail />
                </span>

                <div>
                  <span className="title">Email</span>
                  <span className="value">contact@parousiagroup.com</span>
                </div>
              </div>

              <div className="content">
                <span>
                  <BsGeoAlt />
                </span>

                <div>
                  <span className="title">Address</span>
                  <span className="value">
                    157, Avenue du Livre, Kinshasa-Gombe
                  </span>
                </div>
              </div>

              <div className="content">
                <span>
                  <FiGlobe />
                </span>

                <div>
                  <span className="title">Regional Offices</span>
                  <span className="value">
                    west afrique, central africa, etc
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="form-content card">
            <h3>Send us a Message</h3>

            <div className="content-form">
              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="name">Your name</label>
                  <input
                    type="text"
                    placeholder="Your name"
                    className="form-control"
                    id="name"
                  />
                </div>

                <div className="col-sm-6">
                  <label htmlFor="mail">Your email</label>
                  <input
                    type="email"
                    placeholder="Your email"
                    className="form-control"
                    id="mail"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="Subject">Subject</label>
                <input
                  type="text"
                  placeholder="Subject"
                  className="form-control"
                  id="Subject"
                />
              </div>

              <div className="form-group">
                <label htmlFor="msg">Message</label>
                <textarea
                  rows={5}
                  name=""
                  id="msg"
                  className="form-control"
                  placeholder="Message"
                ></textarea>
              </div>

              <button className="btn-send mt-5">
                <FiSend />
                <span>Send Message</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormContact;
