import React from 'react'
import "./Partenaires.css";
import { FiDisc } from "react-icons/fi";

const Partenaires = () => {
    return (
        <div className='container-partenaires' id='solutions'>
            <div className='partenaires'>
                <h4>Our Values</h4>

                <div className='grille'>
                    <div className='card'>
                        <span>
                            <FiDisc />
                        </span>
                        <span>Integrity</span>
                    </div>

                    <div className='card'>
                        <span>
                            <FiDisc />
                        </span>
                        <span>Innovation</span>
                    </div>

                    <div className='card'>
                        <span>
                            <FiDisc />
                        </span>
                        <span>Customer-centricity</span>
                    </div>

                    <div className='card'>
                        <span>
                            <FiDisc />
                        </span>
                        <span>Empowerment</span>
                    </div>

                    <div className='card'>
                        <span>
                            <FiDisc />
                        </span>
                        <span>Collaboration</span>
                    </div>

                    <div className='card'>
                        <span>
                            <FiDisc />
                        </span>
                        <span>Resilience</span>
                    </div>

                    <div className='card'>
                        <span>
                            <FiDisc />
                        </span>
                        <span>Excellence</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partenaires