import React, { useEffect } from 'react'
import Home from '../../components/home/Home'
import Services from '../../components/services/Services'
import Partenaires from '../../components/partenaires/Partenaires'
import About from '../../components/about/About'
import Contact from '../../components/contacts/Contact'

const Main = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className='App'>
      <Home />
      <About />
      <Services />
      <Partenaires />
      <Contact />
    </div>
  )
}

export default Main