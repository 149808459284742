import React from 'react';
import "./Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className='container-home hero-section'>
            <div className="background-circle circle-1"></div>
            <div className="background-circle circle-2"></div>
            <div className='home'>
                <div className='text-content'>
                    <h1>
                        Empowering Africa's <br /> Digital Future
                    </h1>

                    <p>
                        Leading provider of technology solutions, e-commerce platforms, and digital services  across Africa
                    </p>

                    <div className='btns'>
                        <Link to="">Explore Our Solutions</Link>
                        <Link to="/about">About Us</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home