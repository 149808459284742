import React from 'react'
import "./Contact.css"
import { FiChevronRight, FiMail, FiPhone } from "react-icons/fi";
import { BsGeoAlt } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <div className='contact'>
            <h4>Get in Touch</h4>

            <div className='contentContacts'>
                <div className='content'>
                    <span className='icon'><FiPhone /></span>
                    <span>Call Us</span>
                    <span>+243 892844000</span>
                </div>

                <div className='content'>
                    <span className='icon'> <FiMail /></span>
                    <span>Email Us</span>
                    <span>
                        contact@parousiagroup.com
                    </span>
                </div>

                <div className='content'>
                    <span className='icon'>
                        <BsGeoAlt />
                    </span>
                    <span>Visit Us</span>
                    <span>
                        157, Avenue du Livre, Kinshasa-Gombe
                    </span>
                </div>
            </div>

            <div className='content-link-contact'>
                <Link to="/contact">
                    <span>Contact Us</span>
                    <FiChevronRight />
                </Link>
            </div>
        </div>
    )
}

export default Contact