import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/main/Main";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import FormContact from "./components/contacts/FormContact";
import Info from "./components/about/Info";

function App() {
  return <BrowserRouter>
    <Navbar />
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/contact" element={<FormContact />} />
      <Route path="/about" element={<Info />} />
    </Routes>
    <Footer/>
  </BrowserRouter>
}

export default App;
