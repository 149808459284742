import "./Info.css";

const Info = () => {
  return (
    <div className="infos">
      <div className="info-container">
        <div className="head-infos">
          <h3>About Us</h3>
          <p>About Us</p>
        </div>
      </div>

      <div className="info-body">
        Parousia Group is a Congolese company based in Kinshasa - Gombe, in the
        Democratic Republic of Congo. Founded on Christian principles, our
        company is guided by integrity, compassion and concern for the
        well-being of our customers, our employees and the communities we serve.
      </div>
    </div>
  );
};

export default Info;
