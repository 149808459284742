import React, { useEffect, useState } from 'react'
import "./Services.css";
import { BsTruck, BsHddStack, BsDiagram2, BsChatLeft, BsCreditCard2Back, BsMortarboard } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';


const Services = () => {

    const [windowSize, setWindowSize] = useState(
        window.innerWidth
    );
    useEffect(() => {
        function handleRezise() {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleRezise);

        return () => window.removeEventListener('resize', handleRezise)

    }, [windowSize]);

    return (
        <div className='services' id='services'>
            <h4>Our Services</h4>

            <div className='grille'>

                <div className='card'>
                    <span className='icon'><BsHddStack /></span>
                    <span className='title'>NetVox Intelligence</span>
                    <p>Advanced AI solutions and intelligent systems for business automation</p>
                    <Link to="">
                        <span>Learn More</span>
                        <FiChevronRight />
                    </Link>
                </div>

                <div className='card'>
                    <span className='icon'><BsDiagram2 /></span>
                    <span className='title'>Global Technology Africa</span>
                    <p>GTA is our business solutions arm, offering web services, customer service, VoIP solutions, bulk SMS campaigns, digital marketing, SaaS solutions, and much more.</p>
                    <Link to="">
                        <span>Learn More</span>
                        <FiChevronRight />
                    </Link>
                </div>

                <div className='card'>
                    <span className='icon'>
                        <BsChatLeft /></span>
                    <span className='title'>Afrika Plaza</span>
                    <p>
                        Afrika plaza is our online marketplace platform that connects sellers and buyers, providing a convenient and secure buying and selling experience for a variety of products.
                    </p>
                    <Link to="">
                        <span>Learn More</span>
                        <FiChevronRight />
                    </Link>
                </div>

                <div className='card'>
                    <span className='icon'>
                        <BsTruck /></span>
                    <span className='title'>PAGEXPRESS</span>
                    <p>
                        PAGEXPRESS manages our import-export and logistics activities, offering delivery, sea, river, air and road freight services, as well as delivery of products purchased online.
                    </p>
                    <Link to="">
                        <span>Learn More</span>
                        <FiChevronRight />
                    </Link>
                </div>

                <div className='card'>
                    <span className='icon'>
                        <BsCreditCard2Back /></span>
                    <span className='title'>PAGPay</span>
                    <p>
                        PAGPAY is our Fintech arm, offering secure and convenient payment gateway and online
                        transaction services, integrating various payment methods for optimal user experience.
                    </p>
                    <Link to="">
                        <span>Learn More</span>
                        <FiChevronRight />
                    </Link>
                </div>

                <div className='card'>
                    <span className='icon'>
                        <BsMortarboard /></span>
                    <span className='title'>INTIC</span>
                    <p>
                        INTIC is our dedicated online education arm, offering learners the opportunity to study at the institutions of their choice, wherever they are.
                    </p>
                    <Link to="">
                        <span>Learn More</span>
                        <FiChevronRight />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Services