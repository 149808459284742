import React, { useState } from 'react'
import "./Navbar.css"
import logo from "../../assets/images/logo.svg"
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from 'react-router-dom';

const Navbar = () => {

  const [isHow, setisHow] = useState(false);

  return (
    <div className='container-navbar'>
      <div className='navBar'>
        <Link to="/" className='logo'>
          <img src={logo} alt="Logo" />
        </Link>

        <ul className={isHow ? "show" : ""}>
          <li>
            <a href="#services">About Us</a>
          </li>
          <li>
            <a href="#services">A Services</a>
          </li>
          <li>
            <a href="#solutions">Solutions</a>
          </li>
          <li>
            <Link to="/contact">Contacts</Link>
          </li>

          <li>
            <select className='form-control' style={{
              padding: "5px",
              borderRadius: "3px",
              boxShadow: "none",
              fontSize:"14px"
            }}>
              <option value="EN" key="">EN</option>
              <option value="FR" key="">FR</option>
            </select>
          </li>
        </ul>

        {
          isHow ? <FiX onClick={() => setisHow(!isHow)} /> :
            <FiMenu onClick={() => setisHow(!isHow)} />
        }
      </div>
    </div>
  )
}

export default Navbar