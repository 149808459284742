import React from 'react'
import "./Footer.css"

const Footer = () => {

    const date = new Date();
    const year = date.getFullYear();

    return (
        <div className='footer'>
            <div className='coulumn-1'>
                <ul>
                    <li>About Parwa</li>
                    <li>Company Overview</li>
                    <li>Leadership</li>
                    <li>News</li>
                </ul>

                <ul>
                    <li>Our Services</li>
                    <li>NetVox Intelligence</li>
                    <li>Global Technology Africa</li>
                    <li>Afrika Plaza</li>
                </ul>

                <ul>
                    <li>Contact</li>
                    <li>Get in Touch</li>
                    <li>Support</li>
                    <li>Careers</li>
                </ul>

                <ul>
                    <li>Legal</li>
                    <li>Privacy Policy</li>
                    <li>Terms of Service</li>
                    <li>Compliance</li>
                </ul>
            </div>
            <hr />
            <div className='column-2'>
                &copy; {year} ParousiaGroup. Parousia Group. RCCM: CD/KNG/RCCM/21-B-02650 - ID.NAT: 01-F4300-N90503U All rights reserved.
            </div>
        </div>
    )
}

export default Footer